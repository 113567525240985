import React from "react";
import PropTypes from "prop-types";

import {
  Container,
  Row,
} from "reactstrap";

function CardsHeader({name, parentName}) {
  return (
    <>
      <div className="header pb-5">

        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default CardsHeader;
